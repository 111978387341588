import { default as React, useCallback, useState } from 'react';
import {
  Color,
  Column,
  Row,
  Selector,
  SelectorOptionType,
  splitBorderWidthProps,
  Text,
  TextLink,
  useTelemetry,
} from '@snowflake/core-ui';
import { SignupFormFields, useSignupFormData } from './SignupFormDataContext';
import { FormattedMessage, useIntl } from 'react-intl';
import StepProgressBar from './StepProgressBar';
import { SignupAgreement } from '../SignupCard/SignupAgreement';
import { CardTemplate } from '../../shared/CardTemplate';
import {
  ABExperimentName,
  countries,
  countriesKeyedByIsoCode,
  nonPriorityCountries,
  priorityCountries,
  SignupFormOneFields,
} from '@signup/shared';
import { SignupCardButton } from './SignupCardButton';
import { SignupFormInputs } from './SignupFormInputs';
import { useSignupFormController } from './SignupFormControllerContext';
import { EmailAgreement } from '../../shared/EmailAgreement';
import { useStepOneInputConfigs } from './inputConfigs';
import { InlinedSelector } from '../../shared/InlinedSelector';
import { getExperimentGroup } from '../../../../utils/configurations';
import { SignupEventName } from '../../../../utils/SignupLogger';

interface Props {
  continueNext: () => void;
}

const FormOneFieldNames: SignupFormFields[] = [
  'firstName',
  'lastName',
  'email',
  'signupReason',
  'countryIsoCode',
];

export const SignupFormStepOne = (props: Props) => {
  const formData = useSignupFormData();
  const { setField, countryIsoCode, optInEmailAgreement, optOutEmailAgreement } = formData;
  const { formatMessage } = useIntl();
  const { continueNext } = props;
  const { validateField, signupCardProps } = useSignupFormController();
  const [forceRequired, setForceRequired] = useState(false);
  const inputConfigs = useStepOneInputConfigs();
  const { logAction } = useTelemetry();
  const [formOneStartTime, setFormOneStartTime] = useState<number>(0);

  const countryOptions: SelectorOptionType<string>[] = [
    ...priorityCountries.map(country => {
      return {
        value: country.isoCode,
        label: country.label,
      };
    }),
    { divider: true },
    ...nonPriorityCountries.map(country => {
      return {
        value: country.isoCode,
        label: country.label,
      };
    }),
  ];

  const onContinueButtonClicked = useCallback(() => {
    const invalidFieldNames = FormOneFieldNames.filter(
      fieldName => validateField(fieldName, formData[fieldName]) !== true,
    );

    if (invalidFieldNames.length > 0) {
      logAction(
        SignupEventName.SIGNUP_FORM_ONE_VALIDATION_ERROR_ON_SUBMIT,
        'ui_click',
        'SignupCard',
        {
          firstFormValidationErrors: invalidFieldNames,
        },
      );

      setForceRequired(true);
      return;
    }

    const loggingOptions: Omit<SignupFormOneFields, 'company' | 'role'> = {
      firstName: formData.firstName.trim(),
      lastName: formData.lastName.trim(),
      email: formData.email.trim().toLowerCase(),
      country: countriesKeyedByIsoCode[countryIsoCode].value,
      ...(optOutEmailAgreement !== undefined && { optOutEmailAgreement }), // should only be undefined for 'United States'
      ...(formData.givenEmailOptInChoice && {
        givenEmailOptInChoice: formData.givenEmailOptInChoice,
        optInEmailAgreement,
      }),
      formId: signupCardProps.formId,
      formOneCompletionTime: Date.now() / 1000 - formOneStartTime,
    };

    logAction(SignupEventName.SIGNUP_FORM_ONE_COMPLETE, 'ui_click', 'SignupCard', loggingOptions);

    continueNext();
  }, [
    continueNext,
    validateField,
    formData,
    countryIsoCode,
    optOutEmailAgreement,
    optInEmailAgreement,
    formOneStartTime,
    signupCardProps,
    logAction,
  ]);

  return (
    <CardTemplate>
      <Column
        width="100%"
        rowGap="24px"
        onFocus={() => !formOneStartTime && setFormOneStartTime(Date.now() / 1000)}
      >
        <Row>
          <Column width="100%">
            <StepProgressBar step={1} totalSteps={2}>
              <Text fontSize="16px">
                <FormattedMessage id="Create a Snowflake account" />
              </Text>
            </StepProgressBar>
            <Text color={Color.Gray70}>
              <FormattedMessage
                id="Already have an account? <signin>Sign in</signin>"
                values={{
                  // eslint-disable-next-line
                  signin: (...chunks: any[]) => {
                    let snowflakeLoginUrl = 'https://app.snowflake.com/';

                    //If there are any queryParams, persist them on the login screen
                    const queryParams = window?.location?.search ?? '';
                    if (queryParams) {
                      snowflakeLoginUrl += queryParams;
                    }

                    return (
                      <TextLink
                        href={snowflakeLoginUrl}
                        color={Color.Blue50}
                        onClick={() =>
                          logAction(
                            SignupEventName.GOTO_LOGIN_PAGE_LINK_CLICKED,
                            'ui_click',
                            'SignupPage',
                            {},
                          )
                        }
                        fontWeight="600"
                      >
                        {chunks}
                      </TextLink>
                    );
                  },
                }}
              />
            </Text>
          </Column>
        </Row>
        <Row>
          <SignupFormInputs configs={inputConfigs} forceRequired={forceRequired} />
        </Row>
        <Row>
          <EmailAgreement
            optInEmailAgreement={optInEmailAgreement}
            setOptInEmailAgreement={val => setField('optInEmailAgreement', val)}
            optOutEmailAgreement={optOutEmailAgreement}
            setOptOutEmailAgreement={val => setField('optOutEmailAgreement', val)}
            countryIsoCode={countryIsoCode}
          />
        </Row>
        <Row textAlign="center">
          <SignupAgreement countryIsoCode={countryIsoCode} linkTarget="_blank" />
        </Row>
        <Row>
          <SignupCardButton width="100%" onClick={onContinueButtonClicked}>
            <FormattedMessage id="Continue" />
          </SignupCardButton>
        </Row>
        <Row align="center" fontSize="12px" color={Color.Gray70} columnGap="8px">
          <Column verticalAlign="middle">
            <Text>
              <FormattedMessage id="Country:" />
            </Text>
          </Column>
          <Column>
            {getExperimentGroup(ABExperimentName.SIGNUP_FORM_REWORK_COREUI_COUNTRY_SELECTOR) ===
            'ENABLED' ? (
              <Selector
                {...splitBorderWidthProps(0)}
                options={countryOptions}
                value={countryIsoCode}
                placeholder={formatMessage({ id: 'Country' }) + '*'}
                onChange={e => {
                  setField('countryIsoCode', e.currentTarget.value);
                }}
                flexGrow="0"
              />
            ) : (
              <InlinedSelector
                {...splitBorderWidthProps(0)}
                options={countryOptions}
                value={countryIsoCode}
                label={countries.find(c => c.isoCode === countryIsoCode)?.label}
                placeholder={formatMessage({ id: 'Select a country' })}
                selectValue={item => {
                  setField('countryIsoCode', item);
                }}
                buttonTextColor={forceRequired && !countryIsoCode ? Color.Red50 : undefined}
              />
            )}
          </Column>
        </Row>
      </Column>
    </CardTemplate>
  );
};
