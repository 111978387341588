import { useMemo } from 'react';
import { useSignupFormData } from './SignupFormDataContext';
import { useSignupFormController } from './SignupFormControllerContext';
import { SelectorInput } from './SignupFormInputs';
import { DeploymentOption } from '../../../../models';
import { useIntl } from 'react-intl';
import { ensureExists } from '@signup/shared/src/utils/ensure';
import { SupportedDevRegion, SupportedProdRegion } from '@signup/shared/src/deploymentMetadata';
import { isFeatureEnabled } from '../../../../utils/configurations';
import { FeatureFlag } from '@signup/shared';

export enum RegionGroup {
  NorthAmerica = 'North America',
  SouthAmerica = 'South America',
  Europe = 'Europe',
  AsiaPacific = 'Asia Pacific',
  MiddleEast = 'Middle East',
  Local = 'Local',
  Preprod = 'Preprod',
  Other = 'Other',
}

export type RegionGroupMapping = { [key: string]: RegionGroup };

const ProductionRegionGroupMapping: Record<typeof SupportedProdRegion[number], RegionGroup> = {
  'ap-northeast-1': RegionGroup.AsiaPacific,
  'ap-northeast-2': RegionGroup.AsiaPacific,
  'ap-northeast-3': RegionGroup.AsiaPacific,
  'ap-south-1': RegionGroup.AsiaPacific,
  'ap-southeast-1': RegionGroup.AsiaPacific,
  'ap-southeast-2': RegionGroup.AsiaPacific,
  'ap-southeast-3': RegionGroup.AsiaPacific,
  australiaeast: RegionGroup.AsiaPacific,
  'ca-central-1': RegionGroup.NorthAmerica,
  canadacentral: RegionGroup.NorthAmerica,
  centralindia: RegionGroup.AsiaPacific,
  centralus: RegionGroup.NorthAmerica,
  'east-us-2': RegionGroup.NorthAmerica,
  'eu-central-1': RegionGroup.Europe,
  'eu-central-2': RegionGroup.Europe,
  'eu-north-1': RegionGroup.Europe,
  'eu-west-1': RegionGroup.Europe,
  'eu-west-2': RegionGroup.Europe,
  'eu-west-3': RegionGroup.Europe,
  'europe-west2': RegionGroup.Europe,
  'europe-west3': RegionGroup.Europe,
  'europe-west4': RegionGroup.Europe,
  japaneast: RegionGroup.AsiaPacific,
  northeurope: RegionGroup.Europe,
  'sa-east-1': RegionGroup.SouthAmerica,
  southcentralus: RegionGroup.NorthAmerica,
  southeastasia: RegionGroup.AsiaPacific,
  switzerlandn: RegionGroup.Europe,
  uaenorth: RegionGroup.MiddleEast,
  uksouth: RegionGroup.Europe,
  'us-central1': RegionGroup.NorthAmerica,
  'us-east4': RegionGroup.NorthAmerica,
  'us-east-1': RegionGroup.NorthAmerica,
  'us-east-2': RegionGroup.NorthAmerica,
  'us-west-2': RegionGroup.NorthAmerica,
  'west-europe': RegionGroup.Europe,
  westus2: RegionGroup.NorthAmerica,
  'me-central2': RegionGroup.MiddleEast,
};

const DevAndPreprodRegionGroupMapping: Record<typeof SupportedDevRegion[number], RegionGroup> = {
  preprod8: RegionGroup.Preprod,
  reg: RegionGroup.Local,
};

function useRegionGroupTranslations(): Record<RegionGroup, string> {
  const { formatMessage } = useIntl();
  return {
    [RegionGroup.NorthAmerica]: formatMessage({ id: 'North America' }),
    [RegionGroup.SouthAmerica]: formatMessage({ id: 'South America' }),
    [RegionGroup.Europe]: formatMessage({ id: 'Europe' }),
    [RegionGroup.AsiaPacific]: formatMessage({ id: 'Asia Pacific' }),
    [RegionGroup.MiddleEast]: formatMessage({ id: 'Middle East' }),
    [RegionGroup.Local]: formatMessage({ id: 'Local' }),
    [RegionGroup.Preprod]: formatMessage({ id: 'Preprod' }),
    [RegionGroup.Other]: formatMessage({ id: 'Other' }),
  };
}

export function useSortedRegionSelectList(): SelectorInput<string>['options'] | null {
  const { formatMessage } = useIntl();
  const { cloud } = useSignupFormData();
  const { regionSelectList, regionSelectListSortedByDistance } = useSignupFormController();
  const translations = useRegionGroupTranslations();

  const regionGroupMapping: RegionGroupMapping = useMemo(
    () => ({
      ...ProductionRegionGroupMapping,
      ...DevAndPreprodRegionGroupMapping,
    }),
    [],
  );

  return useMemo(() => {
    if (!isFeatureEnabled(FeatureFlag.FF_ENABLE_GROUPED_REGIONS)) {
      return null;
    }

    if (
      !regionSelectListSortedByDistance ||
      !cloud ||
      !regionSelectList[cloud] ||
      regionSelectList[cloud].length === 0
    ) {
      return null;
    }

    const groupedRegionList: Array<{ group: RegionGroup; options: DeploymentOption[] }> = [];

    // stable sort and group.
    for (const option of regionSelectList[cloud]) {
      const { value: region } = option;
      const group = regionGroupMapping[region] || RegionGroup.Other;
      const groupIndex = groupedRegionList.findIndex(
        groupedRegion => groupedRegion.group === group,
      );
      if (groupIndex === -1) {
        groupedRegionList.push({ group, options: [option] });
      } else {
        groupedRegionList[groupIndex].options.push(option);
      }
    }

    const recommendedOption = ensureExists(groupedRegionList[0].options.shift());
    if (groupedRegionList[0].options.length === 0) {
      groupedRegionList.shift();
    }

    return [
      {
        ...recommendedOption,
        label: formatMessage({ id: '{deploymentName} - Recommended' }).replace(
          '{deploymentName}',
          recommendedOption.label,
        ),
        sublabel: formatMessage({ id: 'Based on your location' }),
      },
      ...groupedRegionList.map(({ group, options }) => ({
        title: translations[group],
        options,
      })),
    ];
  }, [
    regionSelectListSortedByDistance,
    cloud,
    regionSelectList,
    translations,
    formatMessage,
    regionGroupMapping,
  ]);
}
