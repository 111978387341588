import {
  Block,
  Color,
  Input,
  Row,
  Text,
  TextLink,
  useTelemetry,
  ValidationState,
} from '@snowflake/core-ui';
import {
  AwsCapacityFormFields,
  AwsMarketplaceType,
  Cloud,
  SignupApiErrorCode,
  SignupEventType,
  SignupRequestResponse,
  AwsMpAccount,
  AwsAddSubscriptionFormFields,
  AwsFormFields,
  validateInput,
} from '@signup/shared';
import { default as React, FormEvent, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSignupPageContext } from '../../../pages/SignupContext';
import { AwsEventName, AwsSteps } from '../../../utils/SignupLogger';
import { useLogOnClose } from '../../UseLogOnClose';
import { CardTemplate } from '../shared/CardTemplate';
import { BaseCardProps, FormButton, FormRow, sleep } from '../shared/shared';
import { ThankYouCard } from '../ThankYouCard/ThankYouCard';
import * as configs from '../../../utils/configurations';
import { useEmailValidator } from '../utils/validationUtils';
import { getAwsMarketplaceType } from '../../../utils/signupFlowTypeUtils';
import { SignupApi } from '../../../api/signupApi';

export interface AwsCardProps extends BaseCardProps {
  awsMpAccount: AwsMpAccount;
}

export const AwsCard = (props: AwsCardProps) => {
  // Form options
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [accountUrl, setAccountUrl] = useState('');
  const [authorizationToken, setAuthorizationToken] = useState('');

  // Form Validation
  const [firstNameBlurred, setFirstNameBlurred] = useState(false);
  const [lastNameBlurred, setLastNameBlurred] = useState(false);
  const [emailBlurred, setEmailBlurred] = useState(false);
  const [accountUrlBlurred, setAccountUrlBlurred] = useState(false);
  const [authorizationTokenBlurred, setAuthorizationTokenBlurred] = useState(false);
  const [validEmail, setValidEmail] = useState<ValidationState>(ValidationState.NONE);

  //Localization -- message formatter
  const { formatMessage } = useIntl();

  //Set up Telemetry
  const { logAction, logEvent } = useTelemetry();

  // Page State
  // For specific server errors on async requests
  const [errorCode, setErrorCode] = useState<SignupApiErrorCode | undefined>(undefined);
  // For client side errors in event handlers to trigger the event boundary
  // const [error, setError] = useState<Error | undefined>(undefined);
  const [accountCreationFinished, setAccountCreationFinished] = useState(false);
  const [formStartTime, setFormStartTime] = useState<number>(0);
  const [currentStepLogInfo, setCurrentStepLogInfo] = useState({
    currentStep: AwsSteps.AWS_INITIAL_LOAD as string,
    startTime: Date.now() / 1000,
  });

  const { formSubmitted } = useSignupPageContext();

  const handleFormFocus = () => {
    if (formStartTime === undefined) {
      const currTime = Date.now() / 1000;
      setFormStartTime(currTime);
      setCurrentStepLogInfo({ currentStep: AwsSteps.AWS_FORM, startTime: currTime });
    }
  };

  //track the amount of time spent on each form if the user closes the site without completing the form
  useLogOnClose({
    message: 'exit_signup_' + currentStepLogInfo.currentStep,
    startTime: currentStepLogInfo.startTime,
  });

  // Form Handling and Validation
  const validateAndReturnCapacityFields = (
    recaptchaToken: string,
  ): AwsCapacityFormFields | false => {
    const formValidationErrors = [];
    !validateInput(firstName) && formValidationErrors.push('firstName');
    !validateInput(lastName) && formValidationErrors.push('lastName');
    !(email.trim().length && validEmail === ValidationState.VALID) &&
      formValidationErrors.push('email');

    if (formValidationErrors.length) {
      setFirstNameBlurred(true);
      setLastNameBlurred(true);
      setEmailBlurred(true);
      logAction(AwsEventName.AWS_FORM_VALIDATION_ERROR_ON_SUBMIT, 'ui_click', 'AwsCard', {
        formValidationErrors,
      });
      return false;
    } else {
      const formCompletionTime = Date.now() / 1000 - formStartTime;
      const options: AwsCapacityFormFields = {
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        email: email.trim().toLowerCase(),
        cloud: Cloud.AWS,
        ampt: props.signupParams.ampt || '',
        recaptchaToken: recaptchaToken,
        formId: props.formId,
        formCompletionTime,
        awsType: AwsMarketplaceType.CAPACITY,
        awsMpAccount: props.awsMpAccount,
      };
      return options;
    }
  };

  const validateAndReturnOnDemandAddSubFields = (
    recaptchaToken: string,
  ): AwsAddSubscriptionFormFields | false => {
    const formValidationErrors = [];
    !validateInput(firstName) && formValidationErrors.push('firstName');
    !validateInput(lastName) && formValidationErrors.push('lastName');
    !(email.trim().length && validEmail === ValidationState.VALID) &&
      formValidationErrors.push('email');
    !accountUrl.trim().length && formValidationErrors.push('accountUrl');
    !authorizationToken.trim().length && formValidationErrors.push('authorizationToken');

    if (formValidationErrors.length) {
      setFirstNameBlurred(true);
      setLastNameBlurred(true);
      setEmailBlurred(true);
      setAccountUrlBlurred(true);
      setAuthorizationTokenBlurred(true);
      logAction(AwsEventName.AWS_FORM_VALIDATION_ERROR_ON_SUBMIT, 'ui_click', 'AwsCard', {
        formValidationErrors,
      });
      return false;
    } else {
      const formCompletionTime = Date.now() / 1000 - formStartTime;
      const options: AwsAddSubscriptionFormFields = {
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        email: email.trim().toLowerCase(),
        accountUrl: accountUrl.trim(),
        authorizationToken: authorizationToken.trim(),
        cloud: Cloud.AWS,
        ampt: props.signupParams.ampt || '',
        recaptchaToken: recaptchaToken,
        formId: props.formId,
        formCompletionTime,
        awsType: AwsMarketplaceType.ON_DEMAND_ADD_SUBSCRIPTION,
        awsMpAccount: props.awsMpAccount,
      };
      return options;
    }
  };

  async function handleSubmit(e: FormEvent) {
    if (e.preventDefault !== undefined) e.preventDefault();
    // skipping recaptcha on preprod for integration tests.
    const skipRecaptcha = configs.getDeploymentGroup() === 'DEV_DEPLOYMENTS';
    const recaptchaToken = skipRecaptcha
      ? 'skipped'
      : await props.recaptchaRef.current.executeAsync();
    let awsFields: AwsFormFields | false = false;
    if (getAwsMarketplaceType() === AwsMarketplaceType.CAPACITY) {
      awsFields = validateAndReturnCapacityFields(recaptchaToken);
    } else if (getAwsMarketplaceType() === AwsMarketplaceType.ON_DEMAND_ADD_SUBSCRIPTION) {
      awsFields = validateAndReturnOnDemandAddSubFields(recaptchaToken);
    }
    if (awsFields) {
      formSubmitted.setValue(true);
      configs.getDataLayer().push({ event: 'aws self-service trial signup' });
      logAction(AwsEventName.AWS_FORM_COMPLETE, 'ui_click', 'AwsCard', {
        ...awsFields,
        ...(awsFields.recaptchaToken && { recaptchaToken: 'REDACTED' }),
        ...(awsFields.ampt && { ampt: 'REDACTED' }),
      });
      const thankYouPageStartTime = Date.now() / 1000;
      setCurrentStepLogInfo({
        currentStep: AwsSteps.AWS_THANK_YOU_PAGE,
        startTime: thankYouPageStartTime,
      });
      try {
        const rawRes = await SignupApi.addAwsSubscription(awsFields);
        const res: SignupRequestResponse = await rawRes.json();
        if (res.success !== true) {
          await sleep(1000);
          setErrorCode(res.errorCode);
          logEvent({
            event: AwsEventName.AWS_ACCOUNT_ADD_SUBSCRIPTION_ERROR,
            type: SignupEventType.UI_RESPONSE_ERROR,
            data: {
              thankYouResponseDuration: Date.now() / 1000 - thankYouPageStartTime,
            },
            interaction: false,
          });
        }
        setAccountCreationFinished(true);
        logEvent({
          event: AwsEventName.AWS_SUCCESS,
          type: SignupEventType.UI_RESPONSE_SUCCESS,
          data: {
            thankYouResponseDuration: Date.now() / 1000 - thankYouPageStartTime,
          },
          interaction: false,
        });
      } catch (err) {
        await sleep(1000);
        setErrorCode(SignupApiErrorCode.GENERIC_ERROR);
        logEvent({
          event: AwsEventName.AWS_ACCOUNT_ADD_SUBSCRIPTION_ERROR,
          type: SignupEventType.UI_RESPONSE_ERROR,
          data: {
            thankYouResponseDuration: Date.now() / 1000 - thankYouPageStartTime,
          },
          interaction: false,
        });
      }
    }
  }

  const { validateEmailHelper } = useEmailValidator();

  // Form
  const renderForm = () => {
    return (
      <>
        <Block onFocus={handleFormFocus}>
          <Row>
            <Input
              value={firstName}
              placeholder={formatMessage({ id: 'First Name*' })}
              onChange={e => setFirstName(e.currentTarget.value)}
              validation={val => validateInput(val)}
              required={firstNameBlurred}
              onBlur={() => setFirstNameBlurred(true)}
            />
          </Row>
          <FormRow>
            <Input
              value={lastName}
              placeholder={formatMessage({ id: 'Last Name*' })}
              onChange={e => setLastName(e.currentTarget.value)}
              validation={val => validateInput(val)}
              required={lastNameBlurred}
              onBlur={() => setLastNameBlurred(true)}
            />
          </FormRow>
          <FormRow>
            <Input
              value={email}
              placeholder={formatMessage({ id: 'Company Email*' })}
              onChange={e => setEmail(e.currentTarget.value)}
              validation={validateEmailHelper}
              required={emailBlurred}
              onBlur={() => {
                logAction(AwsEventName.AWS_VALIDATE_EMAIL, 'ui_keyboard', 'AwsCard', {
                  emailValidationState: validEmail,
                  email,
                });
                setEmailBlurred(true);
              }}
              onValidationChange={setValidEmail}
            />
          </FormRow>
          {getAwsMarketplaceType() == AwsMarketplaceType.ON_DEMAND_ADD_SUBSCRIPTION && (
            <>
              <FormRow>
                <Input
                  value={accountUrl}
                  placeholder={formatMessage({ id: 'Account URL*' })}
                  onChange={e => setAccountUrl(e.currentTarget.value)}
                  required={accountUrlBlurred}
                  onBlur={() => setAccountUrlBlurred(true)}
                />
              </FormRow>
              <FormRow>
                <Input
                  value={authorizationToken}
                  placeholder={formatMessage({ id: 'Authorization Token*' })}
                  onChange={e => setAuthorizationToken(e.currentTarget.value)}
                  validation={val => val && !!val.trim()}
                  required={authorizationTokenBlurred}
                  onBlur={() => setAuthorizationTokenBlurred(true)}
                />
              </FormRow>
            </>
          )}

          <Row align="center" verticalAlign="top" marginTop={16}>
            <Text size="small" color={Color.Gray70}>
              <FormattedMessage
                id="By clicking the button below you understand that Snowflake will process your personal
                    information in accordance with its <privacynotice>Privacy Notice</privacynotice>"
                values={{
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  privacynotice: function PrivacyNotice(...chunks: any[]) {
                    return (
                      <TextLink href="https://www.snowflake.com/privacy-policy/">{chunks}</TextLink>
                    );
                  },
                }}
              />
            </Text>
          </Row>
          <FormButton onClick={handleSubmit}>
            <FormattedMessage id="Finish Setup" />
          </FormButton>
        </Block>
      </>
    );
  };

  const renderCard = () => {
    const successTitle = formatMessage({ id: 'You are now subscribed to Snowflake!' });
    const successSubtitle = (
      <FormattedMessage id="Nothing else is needed on your end. Your Snowflake representative will be in touch shortly with account activation details." />
    );
    if (formSubmitted.value || !!errorCode) {
      return (
        <ThankYouCard
          signupParams={props.signupParams}
          errorCode={errorCode}
          accountCreationFinished={accountCreationFinished}
          successTitle={successTitle}
          successSubtitle={successSubtitle}
        />
      );
    } else {
      return (
        <CardTemplate
          title={formatMessage({ id: 'Thank you for subscribing to Snowflake!' })}
          subtitle={
            <FormattedMessage id="Please fill out the following information to finish setting up your subscription." />
          }
        >
          {renderForm()}
        </CardTemplate>
      );
    }
  };

  return renderCard();
};
