import { Color, Text } from '@snowflake/core-ui';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSignupPageContext } from '../../pages/SignupContext';

export const CardMarketingTitle = () => {
  const { renderingOptions } = useSignupPageContext();

  const legacyGetCardMarketingTitle = () => (
    <FormattedMessage id="Start your 30-day free Snowflake trial which includes $400 worth of free usage" />
  );

  const title = renderingOptions.cardMarketingTitle || legacyGetCardMarketingTitle();

  return (
    <Text
      className="trial-card-marketing-text"
      fontSize={20}
      color={Color.White}
      lineHeight="30px"
      marginBottom={24}
    >
      {title}
    </Text>
  );
};
