import { Telemetry, UIContext } from '@snowflake/core-ui';
import { SignupFlowType } from '@signup/shared';
import 'core-js/features/object/from-entries';
import 'core-js/features/object/values';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import 'regenerator-runtime/runtime';
import { v4 } from 'uuid';
import { SignupErrorBoundary } from './utils/ErrorBoundary';
import { SignupErrorPage } from './pages/SignupErrorPage';
import { SignupPageRoot } from './pages/SignupPageRoot';
import './styles/trial.scss';
import { AzureEventName, GcpEventName, SignupEventName, SignupLogger } from './utils/SignupLogger';
import {
  AsyncIntlProvider,
  getLocale,
  languageFromLocale,
  loadMessages,
} from './utils/AsyncIntlProvider';
import { getSignupFlowType } from './utils/configurations';

const awaitPolyfills = (async () => {
  const language = languageFromLocale(getLocale());
  let pluralRules;
  let pluralRulesLocaleData;
  let relativeTimeFormat;
  let relativeTimeFormatLocaleData;
  if (!Intl?.PluralRules) {
    pluralRules = import('@formatjs/intl-pluralrules/polyfill');
    pluralRulesLocaleData = import(`@formatjs/intl-pluralrules/locale-data/${language}`);
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if (!(Intl as any)?.RelativeTimeFormat) {
    relativeTimeFormat = import('@formatjs/intl-relativetimeformat/polyfill');
    relativeTimeFormatLocaleData = import(
      `@formatjs/intl-relativetimeformat/locale-data/${language}`
    );
  }
  await Promise.all([
    pluralRules,
    pluralRulesLocaleData,
    relativeTimeFormat,
    relativeTimeFormatLocaleData,
  ]);
})();

const awaitMessages = loadMessages(getLocale(), { isRoot: true });
const formId = v4();
const signupLogger = new SignupLogger(formId, 30000);
Telemetry.registerLogger(signupLogger.processEvent.bind(signupLogger));

const Root = () => (
  <UIContext name="SignupPage">
    <AsyncIntlProvider signupLogger={signupLogger}>
      <SignupErrorBoundary signupLogger={signupLogger}>
        <BrowserRouter>
          <Switch>
            <Route path="/azure" render={props => <SignupPageRoot {...props} formId={formId} />} />
            <Route
              path="/gcp/subscribe"
              render={props => <SignupPageRoot {...props} formId={formId} />}
            />
            <Route
              exact
              path={['/', '/developers']}
              render={props => <SignupPageRoot {...props} formId={formId} />}
            />
            <Route
              exact
              path={['/aws']}
              render={props => <SignupPageRoot {...props} formId={formId} />}
            />
            <Route
              exact
              path={['/aws/capacity']}
              render={props => <SignupPageRoot {...props} formId={formId} />}
            />
            <Route
              exact
              path={['/aws/add-subscription']}
              render={props => <SignupPageRoot {...props} formId={formId} />}
            />
            {/* 404 Page Not Found */}
            <Route
              render={props => <SignupPageRoot {...props} formId={formId} pageNotFound={true} />}
            />
          </Switch>
        </BrowserRouter>
      </SignupErrorBoundary>
    </AsyncIntlProvider>
  </UIContext>
);

const signupEvent = () => {
  switch (getSignupFlowType()) {
    case SignupFlowType.Azure:
      return AzureEventName.AZURE_ROOT_ERROR.toString();
    case SignupFlowType.Gcp:
      return GcpEventName.GCP_ROOT_ERROR.toString();
    default:
      return SignupEventName.SIGNUP_ROOT_ERROR.toString();
  }
};

const renderError = (error: Error) => {
  signupLogger.logError({
    component: 'Root',
    event: signupEvent(),
    data: {
      error: error.toString(),
      componentStack: 'Error occurred in Root while loading the application',
    },
  });
  const ErrorPage = () => (
    <UIContext name="SignupPage">
      <SignupErrorPage />
    </UIContext>
  );

  // eslint-disable-next-line react/no-render-return-value
  return ReactDOM.render(<ErrorPage />, document.getElementById('reactRoot'));
};

document.addEventListener('DOMContentLoaded', async function () {
  try {
    try {
      await Promise.all([awaitMessages, awaitPolyfills]);
    } catch (err) {
      throw new Error(`Error when setting up localization: ${err.toString()}`);
    }
    // eslint-disable-next-line react/no-render-return-value
    return ReactDOM.render(<Root />, document.getElementById('reactRoot'));
  } catch (err) {
    renderError(err);
  }
});
