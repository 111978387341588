// Return all regions if visibleRegions is undefined

import {
  Cloud,
  FeatureFlag,
  SignupEventType,
  isFreemailEmailDomain,
  DeploymentMetadata,
} from '@signup/shared';
import * as configs from './configurations';
import { transform, pickBy } from 'lodash';
import { useTelemetry } from '@snowflake/core-ui';
import { SignupEventName } from './SignupLogger';
import React, { useCallback, useState } from 'react';
import { RegionSelect } from '../models';
import { SignupApi } from '../api/signupApi';
import { BaseCardProps } from '../components/Cards/shared/shared';
import { getAwsMarketplaceType } from './signupFlowTypeUtils';

const AWS_US_EAST_1_REGION = 'us-east-1';
const AWS_AP_SOUTH_1_REGION = 'ap-south-1';

// values are arbitrary as the options are disabled until the visible region data has loaded
export const loadingRegionSelect: RegionSelect = {
  [Cloud.AWS]: [{ label: 'Loading AWS Regions...', value: '', disabled: true }],
  [Cloud.AZURE]: [{ label: 'Loading Azure Regions...', value: '', disabled: true }],
  [Cloud.GCP]: [{ label: 'Loading GCP Regions...', value: '', disabled: true }],
};

const isIPFromIndia = () => {
  const ipIsoCode = configs.getIsoCode();
  return ipIsoCode === 'IN';
};

export type GenerateRegionSelectArgs = {
  signupCardProps?: BaseCardProps;
  visibleRegions?: DeploymentMetadata[];
  email?: string;
};

// return categorized by cloud map of regions
// use all regions if visibleRegions is undefined
// otherwise only use visible regions
export const generateRegionSelectData = ({
  signupCardProps,
  visibleRegions,
  email,
}: GenerateRegionSelectArgs) => {
  let filteredRegions = configs.getDeployments();
  if (
    configs.isFeatureEnabled(FeatureFlag.FF_ENABLE_VA3_MIGRATION) ||
    (configs.isFeatureEnabled(FeatureFlag.FF_ENABLE_VA2_BLOCK) &&
      getAwsMarketplaceType() === undefined &&
      signupCardProps?.signupParams.eng === undefined &&
      signupCardProps?.signupParams.listing === undefined)
  ) {
    filteredRegions = pickBy(filteredRegions, region => region.regionName !== AWS_US_EAST_1_REGION);
  }

  if (
    configs.isFeatureEnabled(FeatureFlag.FF_ENABLE_AWS_MUMBAI_BLOCK) &&
    getAwsMarketplaceType() === undefined &&
    signupCardProps?.signupParams.eng === undefined &&
    signupCardProps?.signupParams.listing === undefined &&
    isIPFromIndia() &&
    email &&
    isFreemailEmailDomain(email)
  ) {
    filteredRegions = pickBy(
      filteredRegions,
      region => region.regionName !== AWS_AP_SOUTH_1_REGION,
    );
  }

  const regionSelect: RegionSelect = transform(
    filteredRegions,
    (mappingResult: RegionSelect, cloudRegion: DeploymentMetadata) => {
      if (
        visibleRegions &&
        visibleRegions.some(region => region.regionName === cloudRegion.regionName)
      ) {
        mappingResult[cloudRegion.cloud].push({
          value: cloudRegion.regionName,
          label: cloudRegion.displayName,
        });
      }
      // Add all regions to the result if there are no visibleRegions passed into the function
      else if (!visibleRegions) {
        mappingResult[cloudRegion.cloud].push({
          value: cloudRegion.regionName,
          label: cloudRegion.displayName,
        });
      }
    },

    {
      [Cloud.AWS]: [],
      [Cloud.AZURE]: [],
      [Cloud.GCP]: [],
    },
  ) as RegionSelect;

  Object.keys(regionSelect).forEach(key => {
    const availableRegions = regionSelect[key as Cloud];
    if (availableRegions.length == 0) {
      availableRegions.push({
        label: 'No available regions for this cloud',
        value: '',
        disabled: true,
      });
    }
  });

  return regionSelect;
};

export const useGetVisibleRegions = (listingGlobalId: string | undefined) => {
  const { logEvent } = useTelemetry();
  const [visibleRegions, setVisibleRegions] = useState<DeploymentMetadata[] | undefined>(undefined);
  const [errorFetchingVisibleRegions, setErrorFetchingVisibleRegions] = useState<boolean>(false);

  const loadVisibleRegions = useCallback(
    async (listingGlobalId: string) => {
      // If a region is not in the visible regions array, then the region won't be included
      // in the region select dropdown
      let regionsToLoad: DeploymentMetadata[] = [];
      try {
        const rawRes = await SignupApi.getDeploymentMetadataByListingGlobalId(listingGlobalId);
        const res = await rawRes.json();
        // On error include all regions in the region select dropdown
        if (rawRes.status >= 400) {
          logEvent({
            event: SignupEventName.FETCH_VISIBLE_REGIONS_FAILS,
            type: SignupEventType.UI_RESPONSE_ERROR,
            data: {
              errorCode: res.errorCode,
            },
            interaction: false,
          });
          regionsToLoad = [...configs.getDeploymentsList()];
          setErrorFetchingVisibleRegions(true);
        } else {
          regionsToLoad = res.data;
        }
      } catch (err) {
        // On error include all regions in the region select dropdown
        logEvent({
          event: SignupEventName.FETCH_VISIBLE_REGIONS_FAILS,
          type: SignupEventType.UI_RESPONSE_ERROR,
          data: {
            error: err,
          },
          interaction: false,
        });
        regionsToLoad = [...configs.getDeploymentsList()];
        setErrorFetchingVisibleRegions(true);
      }
      setVisibleRegions(regionsToLoad);
    },
    [logEvent],
  );

  React.useEffect(() => {
    if (!listingGlobalId) {
      setErrorFetchingVisibleRegions(true);
      return;
    }
    loadVisibleRegions(listingGlobalId);
  }, [listingGlobalId, loadVisibleRegions]);

  return { visibleRegions, errorFetchingVisibleRegions };
};
